/**
 * Created by yantingdiwu on 2021/1/25.
 */

const $ajax = window.util.ajax;
const __index = {
    changeTabFun() {
        const idx = $(this).index();

        $(this).addClass('active').siblings().removeClass('active');
        $('.part1-content .part1-content-item').addClass('hide').eq(idx).removeClass('hide');
    },
    submitFormFun() {
        const fromData = $('._form').serializeArray();
        let SubmitData = {},
            flag = true,
            reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        fromData.forEach((item) => {
            const name = item.name,
                value = item.value,
                $inputParents = $(`input[name='${name}']`).parents('.input');

            if (!value && name !== 'message') {
                $inputParents.addClass('error');
                flag = false;
            } else {
                $inputParents.removeClass('error');
                SubmitData[name] = value;
            }
        })

        if (!flag) {
            return false;
        }

        if (SubmitData.mobile.length < 11) {
            layer.msg('请输入正确的联系电话');
            return false;
        }

        if (!reg.test(SubmitData.email)) {
            layer.msg('请输入正确的邮箱地址');
            return false;
        }

        $ajax({
            url: '/apply/save',
            type: "post",
            data: SubmitData,
            callback: ({data}) => {
                layer.msg('提交成功');

                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
        })

    },
    initSwiper(){

        new Swiper('._swiper', {
            speed: 1000,
            autoplay: 3000,
            loop: true
        });
    },
    showWaitingMag(){
        layer.msg('敬请期待');
    }
}

$(() => {

    __index.initSwiper();

    $(document)
        .on('click', '.part1-tab-item', __index.changeTabFun)
        .on('click', '._waiting', __index.showWaitingMag)
        .on('click', '._submitBtn', __index.submitFormFun)

})